import React, { useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { useNewsletter } from '../../../_functions';
import { useToast } from '../../../_functions/toast';

export const CreatePartner = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
  const { partners, createPartner } = useNewsletter();
  const { addToast } = useToast();

  const [name, setName] = useState<string | undefined>(undefined);

  const closeModal = () => {
    setName(undefined);
    handleClose();
  };

  const finish = () => {
    if (name) {
      const partnerAlreadyAssign = partners.find((p) => p.companie.name === name);
      if (partnerAlreadyAssign) {
        addToast({
          title: 'Keine Dupletten',
          text: <p>Der Partner existiert bereits!</p>,
          color: 'warning',
          iconType: 'cross',
        });
      } else {
        createPartner(name);
      }
    }
    closeModal();
  };

  return (
    <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Neuen Partner erstellen</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow label="Name" helpText="Name des Unternehmens">
            <EuiFieldText
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
        <EuiButton disabled={name === undefined} onClick={finish}>
          Erstellen
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export const CreatePartnerUser = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
  const { partners, createPartnerUser } = useNewsletter();

  const [fname, setFname] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [lname, setLname] = useState<string | undefined>(undefined);
  const [partner, setPartner] = useState<Array<EuiComboBoxOptionOption<string>>>([]);

  const onPartnerChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    if (options.length > 0) {
      setPartner(options);
    }
  };
  const closeModal = () => {
    setFname(undefined);
    setLname(undefined);
    setPartner([]);
    setEmail(undefined);
    handleClose();
  };

  const finish = () => {
    if (fname?.trim() && lname?.trim() && email?.trim() && partner[0].value?.trim()) {
      createPartnerUser(fname, lname, email, partner[0].value);
      closeModal();
    } else {
      console.error('All fields are required to create a partner user.');
    }
  };

  return (
    <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Neuen Partner Nutzer erstellen</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow label="Vorname">
            <EuiFieldText
              onChange={(e) => {
                setFname(e.target.value);
              }}
            />
          </EuiFormRow>
        </EuiForm>
        <EuiForm component="form">
          <EuiFormRow label="Nachname">
            <EuiFieldText
              onChange={(e) => {
                setLname(e.target.value);
              }}
            />
          </EuiFormRow>
        </EuiForm>
        <EuiForm component="form">
          <EuiFormRow label="E-Mail">
            <EuiFieldText
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </EuiFormRow>
        </EuiForm>
        <EuiFormRow label="Partner">
          <EuiComboBox
            placeholder="Select a Partner"
            options={partners.map((p) => ({
              label: p.companie.name,
              value: p.companie.id,
            }))}
            onChange={onPartnerChange}
            selectedOptions={partner}
            singleSelection={{ asPlainText: true }}
            isClearable={true}
          />
        </EuiFormRow>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
        <EuiButton
          disabled={
            fname === undefined ||
            lname === undefined ||
            partner.length === 0 ||
            email === undefined
          }
          onClick={finish}
        >
          Erstellen
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
