import { EuiConfirmModal, EuiTitle } from '@elastic/eui';
import { useNewsletter } from '../../../_functions';
import { Events } from '../../../_types';

export const DeleteEventModal = ({
  selectedEvent: selectedPartner,
  handleClose,
}: {
  selectedEvent: Events;
  handleClose: () => void;
}): JSX.Element => {
  const { deleteEvent } = useNewsletter();

  const finish = () => {
    deleteEvent(selectedPartner.event.id);
    handleClose();
  };
  return (
    <EuiConfirmModal
      title={`Partner ${selectedPartner.event.name} Löschen`}
      onCancel={() => {
        handleClose();
      }}
      onConfirm={() => finish()}
      cancelButtonText="Abrechen"
      confirmButtonText={`${selectedPartner.event.name} löschen`}
      buttonColor="danger"
      defaultFocusedButton="confirm"
    >
      <p>Sind Sie sich sicher, dass Sie den Partner {selectedPartner.event.name} löschen wollen?</p>
      <p>Es werden ausserdem folgende Refetenzen gelöscht:</p>
      {selectedPartner.guests.length > 0 && (
        <>
          <EuiTitle>
            <h3>Gäste</h3>
          </EuiTitle>
          <ul>
            {selectedPartner.guests.map((g) => {
              return (
                <li>
                  {g.firstname} {g.lastname}({g.email})
                </li>
              );
            })}
          </ul>
        </>
      )}
      {selectedPartner.partner.length > 0 && (
        <>
          <EuiTitle>
            <h3>Partner</h3>
          </EuiTitle>
          <ul>
            {selectedPartner.partner.map((p) => {
              return <li>{p.name}</li>;
            })}
          </ul>
        </>
      )}
      {selectedPartner.presentations.length > 0 && (
        <>
          <EuiTitle>
            <h3>Presentations</h3>
          </EuiTitle>
          <ul>
            {selectedPartner.presentations.map((p) => {
              return (
                <li>
                  {p.title}({p.partner.name})
                </li>
              );
            })}
          </ul>
        </>
      )}
    </EuiConfirmModal>
  );
};
