import { BasicUser, Campaign, Companie, UserDetail, UserNewsletter } from '../../_types';
import { OpsTable } from '../../components/tabel';
import {
  EuiAccordion,
  EuiBadge,
  EuiBasicTableColumn,
  EuiButtonGroup,
  EuiButtonIcon,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiCommentList,
  EuiCommentProps,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { useEffect, useRef, useState } from 'react';
import { useNewsletter } from '../../_functions';
import { DeleteUserModal } from '../modal';
import moment from 'moment';
import * as XLSX from 'xlsx';

export const UserTable = (): JSX.Element => {
  const { customers, importUsers } = useNewsletter();
  const [selectedUser, setSelectedUser] = useState<UserDetail>();
  const [modal, setModal] = useState<'delete' | 'none'>('none');

  const handleClose = () => {
    setModal('none');
  };

  const columnsUser: EuiBasicTableColumn<UserDetail>[] = [
    {
      field: 'title',
      name: 'Title',
      sortable: true,
      truncateText: true,
      render: (title: string, user: UserDetail) => {
        return (
          <div key={`${user.id}:title`} style={{ minWidth: '100px' }}>
            {title}
          </div>
        );
      },
    },
    {
      field: 'firstname',
      name: 'Vorname',
      sortable: true,
      truncateText: true,
      render: (firstname: string, user: UserDetail) => {
        return (
          <div key={`${user.id}:name`} style={{ minWidth: '100px' }}>
            {firstname}
          </div>
        );
      },
    },
    {
      field: 'lastname',
      name: 'Nachname',
      sortable: true,
      truncateText: true,
      render: (lastname: string, user: UserDetail) => {
        return (
          <div key={`${user.id}:lastname`} style={{ minWidth: '100px' }}>
            {lastname}
          </div>
        );
      },
    },
    {
      field: 'email',
      name: 'E-Mail',
      sortable: true,
      truncateText: true,
      render: (value: string, user: UserDetail) => {
        return (
          <div key={`${user.id}:email`} style={{ minWidth: '100px' }}>
            {value}
          </div>
        );
      },
    },
    {
      field: 'company',
      name: 'Unternehmen',
      sortable: true,
      truncateText: true,
      render: (company: Companie, user: UserDetail) => {
        return (
          <div key={`${user.id}:company`} style={{ minWidth: '100px' }}>
            {company.name}
          </div>
        );
      },
    },
    {
      field: 'partners',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (partners: Companie[], user: UserDetail) => {
        return (
          <div key={`${user.id}:partner`} style={{ minWidth: '100px' }}>
            {partners[0]?.name}
          </div>
        );
      },
    },
    {
      field: 'events',
      name: 'Events',
      width: '15%',
      sortable: true,
      truncateText: true,
      render: (events: Companie[], user: UserDetail) => {
        return (
          <div
            key={`${user.id}:events`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            }}
          >
            {events.forEach((e) => {
              <EuiBadge key={`${user.id}:events:${e.id}`}>{e?.name}</EuiBadge>;
            })}
          </div>
        );
      },
    },
    {
      field: 'campaign',
      name: 'Kampange',
      sortable: true,
      truncateText: true,
      render: (campaign: Campaign, user: UserDetail) => {
        if (campaign !== undefined && campaign.name !== null) {
          return (
            <div key={`${user.id}:campaign`} style={{ minWidth: '100px' }}>
              {campaign.name}
            </div>
          );
        }
      },
    },
    {
      field: 'newsletters',
      name: 'aboniert',
      render: (newsletters: UserNewsletter[], user: UserDetail) => {
        if (newsletters === null || newsletters.length === 0) {
          return <p key={`${user.id}:newsletter`}>Keine Abos</p>;
        } else {
          return (
            <div>
              {newsletters.map((news, idx) => {
                return (
                  <EuiBadge
                    key={`${user.id}:newsletter:${idx}`}
                    color={news.subscriber ? 'green' : 'red'}
                  >
                    {news.name}
                  </EuiBadge>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      field: 'action',
      name: '',
      actions: [
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: UserDetail) => {
            setSelectedUser(item);
            setModal('delete');
          },
        },
      ],
      width: '100px',
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={customers}
        compressed={true}
        columns={columnsUser}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsStorageKey="user:full"
        opsSearch={true}
        opsFilterColumns={[
          'campaign',
          'company',
          'email',
          'firstname',
          'lastname',
          'newsletters',
          'title',
        ]}
        opsImportAction={importUsers}
      />
      {modal === 'delete' && selectedUser && (
        <DeleteUserModal handleClose={handleClose} selectedUser={selectedUser} />
      )}
    </>
  );
};

export const EventUserTable = ({ customers }: { customers: BasicUser[] }): JSX.Element => {
  const columnsUser: EuiBasicTableColumn<BasicUser>[] = [
    {
      field: 'title',
      name: 'Title',
      sortable: true,
      truncateText: true,
      render: (title: string, user: BasicUser) => {
        return (
          <div key={`${user.id}:title`} style={{ minWidth: '100px' }}>
            {title}
          </div>
        );
      },
    },
    {
      field: 'firstname',
      name: 'Vorname',
      sortable: true,
      truncateText: true,
      render: (firstname: string, user: BasicUser) => {
        return (
          <div key={`${user.id}:name`} style={{ minWidth: '100px' }}>
            {firstname}
          </div>
        );
      },
    },
    {
      field: 'lastname',
      name: 'Nachname',
      sortable: true,
      truncateText: true,
      render: (lastname: string, user: BasicUser) => {
        return (
          <div key={`${user.id}:lastname`} style={{ minWidth: '100px' }}>
            {lastname}
          </div>
        );
      },
    },
    {
      field: 'email',
      name: 'E-Mail',
      sortable: true,
      truncateText: true,
      render: (value: string, user: BasicUser) => {
        return (
          <div key={`${user.id}:email`} style={{ minWidth: '100px' }}>
            {value}
          </div>
        );
      },
    },
    {
      field: 'company',
      name: 'Unternehmen',
      sortable: true,
      truncateText: true,
      render: (company: Companie, user: BasicUser) => {
        return (
          <div key={`${user.id}:company`} style={{ minWidth: '100px' }}>
            {company.name}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={customers}
        compressed={true}
        columns={columnsUser}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsStorageKey="user:event"
        opsSearch={true}
        opsFilterColumns={['company', 'email', 'firstname', 'lastname', 'title']}
      />
    </>
  );
};

type GuestTypes = 'all' | 'presentation' | 'interaction';
export const EventUserComment = ({
  customers,
  event,
}: {
  customers: BasicUser[];
  event: string;
}): JSX.Element => {
  const { getEventPartner } = useNewsletter();
  const [selectedPartners, setSelctedPartners] = useState<Array<EuiComboBoxOptionOption<string>>>(
    []
  );
  const [selectedCustomers, setSelctedCustomers] = useState<Array<EuiComboBoxOptionOption<string>>>(
    []
  );
  const [toggleIdSelected, setToggleIdSelected] = useState<GuestTypes>('all');

  const getCompanies = (): Array<EuiComboBoxOptionOption<string>> => {
    const companiesMap = new Map<string, EuiComboBoxOptionOption<string>>();

    customers.forEach((c) => {
      if (!companiesMap.has(c.company.id) && c.company.id !== null) {
        companiesMap.set(c.company.id, {
          label: c.company.name,
          value: c.company.id,
        });
      }
      return null;
    });

    return Array.from(companiesMap.values());
  };

  const commentMapRef = useRef(new Map<string, EuiCommentProps[]>());

  const setGlobalComments = (customerId: string, comments: EuiCommentProps[]) => {
    const existingComments = commentMapRef.current.get(customerId) || [];
    const isSame =
      existingComments.length === comments.length &&
      existingComments.every((c, i) => c.timestamp === comments[i].timestamp);

    if (!isSame) {
      commentMapRef.current.set(customerId, comments);
    }
  };

  const generateCSV = () => {
    const values = {
      customer: selectedCustomers[0]?.label
        ? `${selectedCustomers[0].label.replaceAll(' ', '-').toLowerCase()}-`
        : '',
      partner: selectedPartners[0]?.label
        ? `${selectedPartners[0].label.replaceAll(' ', '-').toLowerCase()}-`
        : '',
      toggleIdSelected,
    };
    const fileName = values.customer + values.partner + values.toggleIdSelected + '.csv';

    const visibleComments = Array.from(commentMapRef.current.values()).flat();

    const commentsData = visibleComments;

    const ws = XLSX.utils.json_to_sheet(commentsData);

    const wb = { Sheets: { Comments: ws }, SheetNames: ['Comments'] };

    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <EuiFlexGrid>
      <EuiFlexItem>
        <EuiSpacer />
      </EuiFlexItem>
      <EuiFlexItem grow style={{ width: '100%' }}>
        <EuiFlexGrid direction="row">
          <EuiFlexItem>
            <EuiComboBox
              style={{
                width: '350px',
              }}
              options={getEventPartner(event).map((c) => ({
                label: c.companie.name,
                value: c.companie.id,
              }))}
              selectedOptions={selectedPartners}
              onChange={setSelctedPartners}
              isClearable={true}
              placeholder="Partner"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiComboBox
              style={{
                width: '350px',
              }}
              options={getCompanies()}
              selectedOptions={selectedCustomers}
              onChange={setSelctedCustomers}
              isClearable={true}
              placeholder="Unternhemen"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButtonGroup
              type="single"
              legend={''}
              name={''}
              options={[
                {
                  id: `all`,
                  label: 'Alle',
                },
                {
                  id: `presentation`,
                  label: 'Präsentationen',
                },
                {
                  id: `interaction`,
                  label: 'Stand',
                },
              ]}
              idSelected={toggleIdSelected}
              onChange={(optionId) => {
                setToggleIdSelected(optionId as GuestTypes);
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButtonIcon
              iconType="download"
              onClick={() => {
                generateCSV();
              }}
            />
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiFlexItem>
      <EuiFlexItem>
        {customers.map((customer, idx) => {
          return (
            <EuiUserComment
              key={idx}
              customer={customer}
              event={event}
              partners={selectedPartners.map((p) => p.label)}
              show={toggleIdSelected}
              customers={selectedCustomers.map((p) => p.label)}
              setGlobalComments={setGlobalComments}
            />
          );
        })}
      </EuiFlexItem>
    </EuiFlexGrid>
  );
};

const EuiUserComment = ({
  customer,
  event,
  partners,
  customers,
  show,
  setGlobalComments,
}: {
  customer: BasicUser;
  event: string;
  partners: string[];
  customers: string[];
  show: GuestTypes;
  setGlobalComments: (customerId: string, comments: EuiCommentProps[]) => void;
}): JSX.Element => {
  const { getUserEventDetails } = useNewsletter();
  const [comments, setComments] = useState<EuiCommentProps[]>([]);

  const userDetail = async () => {
    const values = await getUserEventDetails(event, customer.id);
    if (values) {
      const _comments: EuiCommentProps[] = [];
      const downloadCommentValue: any[] = [];

      if (show === 'all' || show === 'interaction') {
        if (Array.isArray(values.interactions)) {
          values.interactions.forEach((interaction) => {
            const partnerCompany = interaction.partner_user?.company || '';
            const partnerEmail = interaction.partner_user?.email || 'Unknown Email';
            if (interaction.created_at !== null) {
              if (customers.length === 0 || customers.includes(customer.company.name)) {
                if (partners.length === 0 || partners.includes(partnerCompany)) {
                  const comment: EuiCommentProps = {
                    username: `${customer.firstname} ${customer.lastname}${
                      customer.company.name ? ` (${customer.company.name})` : ''
                    }`,
                    event: `sprach mit ${partnerEmail} (${partnerCompany || 'Unknown Company'}) um`,
                    timestamp: moment(interaction.created_at).format('lll'),
                    type: 'regular',
                    children: (
                      <EuiText>
                        <p>{interaction.message}</p>
                      </EuiText>
                    ),
                    timelineIcon: 'reporter',
                  };

                  downloadCommentValue.push({
                    'Kunde Unternehmen':
                      customer.company.name !== null ? customer.company.name : '',
                    'Kunde Kontakt': `${customer.firstname} ${customer.lastname}`,
                    Zeitpunkt: moment(interaction.created_at).format('lll'),
                    Type: 'Gespräch',
                    Inhalt: interaction.message,
                  });
                  _comments.push(comment);
                }
              }
            }
          });
        }
      }

      if (show === 'all' || show === 'presentation') {
        if (Array.isArray(values.presentations)) {
          const partnerCompany = customer.company.name || '';
          if (customers.length === 0 || customers.includes(customer.company.name)) {
            if (partners.length === 0 || partners.includes(partnerCompany)) {
              values.presentations.forEach((presentation) => {
                if (presentation.time !== null) {
                  const comment: EuiCommentProps = {
                    username: `${customer.firstname} ${customer.lastname}${
                      customer.company.name ? ` (${customer.company.name})` : ''
                    }`,
                    event: `besuchte die Presentation ${presentation.title} um`,
                    timestamp: moment(presentation.time).format('lll'),
                    type: 'regular',
                    timelineIcon: 'calendar',
                  };

                  downloadCommentValue.push({
                    'Kunde Unternehmen':
                      customer.company.name !== null ? customer.company.name : '',
                    'Kunde Kontakt': `${customer.firstname} ${customer.lastname}`,
                    Zeitpunkt: moment(presentation.time).format('lll'),
                    Type: 'Präsentation',
                    Inhalt: '',
                  });

                  _comments.push(comment);
                }
              });
            }
          }
        }
      }

      const sortedComments = _comments.sort(
        (a, b) =>
          new Date(a.timestamp as string).getTime() - new Date(b.timestamp as string).getTime()
      );

      const sortedDownloadComments = downloadCommentValue.sort(
        (a, b) =>
          new Date(a.timestamp as string).getTime() - new Date(b.timestamp as string).getTime()
      );

      return { sortedComments, sortedDownloadComments };
    }
    return { sortedComments: [], sortedDownloadComments: [] };
  };

  const updateComments = async () => {
    const sortedComments = await userDetail();
    setGlobalComments(customer.id, sortedComments.sortedDownloadComments);
    setComments(sortedComments.sortedComments);
  };
  useEffect(() => {
    updateComments();
  }, [customer, event, partners, show]);

  if (comments.length !== 0) {
    return (
      <EuiAccordion
        id={`user:detail:accordion:${customer.id}`}
        buttonContent={
          <EuiText>
            <h3>{`${customer.firstname} ${customer.lastname}`}</h3>
          </EuiText>
        }
        paddingSize="l"
      >
        <EuiPanel>
          <EuiCommentList
            key={`user:detail:accordion:${customer.id}:comments`}
            comments={comments}
          />
        </EuiPanel>
      </EuiAccordion>
    );
  } else {
    return <></>;
  }
};
