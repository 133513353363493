import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiComboBoxOptionOption,
  EuiComboBox,
  EuiSpacer,
  EuiPanel,
  EuiButtonIcon,
  EuiTableFieldDataColumnType,
  EuiBasicTableColumn,
  EuiModal,
  EuiModalHeader,
  EuiButton,
  EuiModalBody,
} from '@elastic/eui';
import { ColumnSort } from './columnsort';
import { ColumnConfig, ConfigType, OpsTableProps } from '../../_types';
import { useToast } from '../../_functions/toast';
import { UserImportModal } from '../../newletter/modal';

export const OpsTable = <T,>(props: OpsTableProps<T>): JSX.Element => {
  const { addToast } = useToast();

  const [sortedItems, setSortedItems] = useState(props.items);

  const [columnFilters, setColumnFilters] = useState<{ [key: string]: string[] }>({});
  const [searchValue, setSearchValue] = useState<string>('');

  const [showModal, setShowModal] = useState<'edit' | 'import' | false>(false);
  const [modalType, setModalType] = useState<ConfigType>('column');

  const [columnConfig, setColumnConfig] = useState<ColumnConfig<T>>({});

  const [filterConfig, setFilterConfig] = useState<ColumnConfig<T>>({});

  const getUniqueValueObject = (obj: any): string | null => {
    let value: string | null = null;

    const searchValue = (item: any): string | null => {
      if (item === null || typeof item === 'undefined') return null;

      if (typeof item === 'object') {
        if (Array.isArray(item)) {
          for (const element of item) {
            const result = searchValue(element);
            if (result !== null) return result;
          }
        } else {
          for (const key in item) {
            if (key !== 'id') {
              const result = searchValue(item[key]);
              if (result !== null) return result;
            }
          }
        }
      } else {
        return String(item);
      }

      return null;
    };

    value = searchValue(obj);
    return value;
  };

  const closeModalHandler = () => {
    closeModal('cancle');
  };

  const applyFilters = (filteredItems: T[]) => {
    let filteredResults = filteredItems;

    Object.keys(columnFilters).forEach((columnName: string) => {
      const columnKey = columnName as keyof T;
      const filterValues = columnFilters[columnKey as string];
      if (filterValues && filterValues.length > 0) {
        filteredResults = filteredResults.filter((item: T) => {
          const itemValue = item[columnKey];
          if (itemValue !== null) {
            if (Array.isArray(itemValue)) {
              return itemValue.some((value) => {
                if (typeof value === 'object') {
                  return Object.keys(value).some((key) => {
                    return filterValues.includes(String(value[key]));
                  });
                } else {
                  return filterValues.includes(String(value));
                }
              });
            } else if (typeof itemValue === 'object') {
              return Object.keys(itemValue).some((key) => {
                return filterValues.includes(String((itemValue as { [key: string]: any })[key]));
              });
            } else {
              return filterValues.includes(String(itemValue));
            }
          }
          return false;
        });
      }
    });

    setSortedItems(filteredResults);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchValue(query);

    const filteredItems = props.items.filter((item: T) => {
      return Object.values(item as Record<string, unknown>).some((value) =>
        String(value).toLowerCase().includes(query)
      );
    });

    applyFilters(filteredItems);
  };

  const handleColumnFilterChange = (
    columnName: keyof T,
    selectedOptions: EuiComboBoxOptionOption<string>[]
  ) => {
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .filter((value): value is string => typeof value === 'string');

    setColumnFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: selectedValues,
    }));
  };

  const getUniqueColumnValues = (columnName: keyof T): Array<EuiComboBoxOptionOption<string>> => {
    const uniqueValues = new Set<string>();
    if (Array.isArray(props.items) && props.items.length > 0) {
      props.items.forEach((item) => {
        const columnValue = item[columnName];

        const processValue = (val: any) => {
          if (val !== undefined && val !== null) {
            if (Array.isArray(val)) {
              val.forEach((v) => {
                if (typeof v === 'object') {
                  const uniqueObjectValues = getUniqueValueObject(v);
                  if (uniqueObjectValues !== null) {
                    uniqueValues.add(uniqueObjectValues);
                  }
                } else {
                  uniqueValues.add(String(v));
                }
              });
            } else if (typeof val === 'object') {
              const uniqueObjectValues = getUniqueValueObject(val);
              if (uniqueObjectValues !== null) {
                uniqueValues.add(uniqueObjectValues);
              }
            } else {
              uniqueValues.add(String(val));
            }
          }
        };

        processValue(columnValue);
      });
    }

    return Array.from(uniqueValues).map((value) => ({ label: value, value }));
  };

  const changeColumnConfig = (config: ColumnConfig<T>) => {
    setColumnConfig(config);
    closeModal('finish');
  };

  const changeFilterConfig = (config: ColumnConfig<T>) => {
    setFilterConfig(config);
    closeModal('finish');
  };

  const closeModal = (state: 'cancle' | 'finish') => {
    setShowModal(false);
    if (state === 'finish') {
      addToast({
        title: 'Notification',
        text: 'Ihre ändeungen wurden übernommen!',
        color: 'success',
        iconType: 'check',
      });
    }
  };

  useEffect(() => {
    const initialConfig: ColumnConfig<T> = {};
    const initFilterConfig: ColumnConfig<T> = {};

    props.columns.forEach((column, idx) => {
      if (column && 'field' in column) {
        const typedColumn = column as EuiTableFieldDataColumnType<T>;
        const fieldName = typedColumn.field as string;
        initialConfig[fieldName] = {
          visible: true,
          priority: idx,
        };
      }
    });

    props.opsFilterColumns?.forEach((filter, idx) => {
      if (filter) {
        const fieldName = filter;
        initFilterConfig[fieldName] = {
          visible: true,
          priority: idx,
        };
      }
    });

    if (props.opsStorageKey) {
      const saveFilterConf = localStorage.getItem(`anketa:table:storage:${props.opsStorageKey}`);
      if (saveFilterConf) {
        setColumnConfig(JSON.parse(saveFilterConf));
      }
    }

    setFilterConfig(initFilterConfig);
    setColumnConfig(initialConfig);
  }, []);

  useEffect(() => {
    applyFilters(props.items);
  }, [props.items, columnFilters]);

  useEffect(() => {
    if (props.opsStorageKey) {
      localStorage.setItem(
        `anketa:table:storage:${props.opsStorageKey}`,
        JSON.stringify(columnConfig)
      );
    }
  }, [columnConfig]);

  return (
    <EuiPanel>
      <EuiFlexGroup
        component="span"
        gutterSize="l"
        direction="row"
        wrap={true}
        alignItems="flexStart"
      >
        {props.opsSearch && (
          <EuiFlexItem grow={4}>
            <EuiFieldSearch
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchChange}
              aria-label="Search"
            />
          </EuiFlexItem>
        )}
        {props.opsFilterColumns
          ?.filter((key) => filterConfig.hasOwnProperty(key))
          .sort((a, b) => {
            const priorityA = filterConfig[a].priority;
            const priorityB = filterConfig[b].priority;
            return priorityA - priorityB;
          })
          .filter((fltr) => {
            return filterConfig[fltr].visible === true;
          })
          .map((columnName) => {
            if (String(columnName) !== 'action') {
              return (
                <EuiFlexItem key={String(columnName)} grow={3}>
                  <EuiComboBox
                    placeholder={`Filter by ${String(columnName.replace('_', ' '))}`}
                    options={getUniqueColumnValues(columnName)}
                    selectedOptions={
                      columnFilters[columnName]
                        ? columnFilters[columnName].map((value) => ({
                            label: value,
                            value: value || '',
                          }))
                        : []
                    }
                    onChange={(selectedOptions) =>
                      handleColumnFilterChange(columnName as keyof T, selectedOptions)
                    }
                    isClearable
                  />
                </EuiFlexItem>
              );
            }
          })}
        <EuiButtonIcon
          iconType={'gear'}
          onClick={() => {
            setShowModal('edit');
          }}
          aria-label="placeholdericon-settings-0"
        />
        {props.opsImportAction && (
          <EuiButtonIcon
            iconType={'importAction'}
            onClick={() => {
              setShowModal('import');
            }}
            aria-label="placeholdericon-settings-1"
          />
        )}
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiInMemoryTable
        {...props}
        items={sortedItems}
        columns={Object.entries(columnConfig)
          .sort(([, a], [, b]) => a.priority - b.priority)
          .map(([key, config]) => {
            const foundColumn = props.columns.find((column) => {
              if ('field' in column && column.field === key) {
                return true;
              } else if ('name' in column && column.name === key) {
                return true;
              }
              return false;
            });

            if (foundColumn && config.visible) {
              return foundColumn as EuiBasicTableColumn<T>;
            }
            return null;
          })
          .filter((column): column is EuiBasicTableColumn<T> => !!column)}
      />
      {showModal === 'edit' && (
        <EuiModal onClose={() => closeModal('cancle')} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiButton
              onClick={() => {
                setModalType('column');
              }}
              fill={modalType === 'column' ? true : false}
              color={modalType === 'column' ? 'primary' : 'secondary'}
            >
              Spalten
            </EuiButton>
            <EuiButton
              onClick={() => {
                setModalType('filter');
              }}
              fill={modalType === 'filter' ? true : false}
              color={modalType === 'filter' ? 'primary' : 'secondary'}
            >
              Filter
            </EuiButton>
          </EuiModalHeader>
          <EuiModalBody
            style={{
              minHeight: '600px',
            }}
          >
            {modalType === 'column' ? (
              <ColumnSort
                changeConfig={changeColumnConfig}
                closeModal={closeModal}
                initConfig={columnConfig}
                key={'newsletter:sort:column:'}
              />
            ) : (
              <ColumnSort
                changeConfig={changeFilterConfig}
                closeModal={closeModal}
                initConfig={filterConfig}
                key={'newsletter:sort:filter:'}
              />
            )}
          </EuiModalBody>
        </EuiModal>
      )}
      {showModal === 'import' && props.opsImportAction && (
        <UserImportModal
          handleUplod={props.opsImportAction}
          handleClose={closeModalHandler}
          typeDefinition={{
            firstname: '',
            surename: '',
            title: '',
            email: '',
            comapny: '',
            campgane: '',
          }}
        />
      )}
    </EuiPanel>
  );
};
