import React from 'react';
import { Route, Routes } from 'react-router';
import { useKeycloak } from './_functions/auth/keycloak';
import Questinary from './questinary';
import { NewsletterComponent } from './newletter';
import { AuthorizeItem } from './_functions/auth/authorize';
import { NewsletterLeave } from './newletter/leave';
import { AppointmentComponent } from './newletter/appointment';
import QuestinarySupport from './catalogs/onboarding/questinary';
import { EventDetection, EventHomepage } from './event';
import { PublicPresentations } from './event/presentationsPublic';

export const App = (): JSX.Element => {
  const authCtx = useKeycloak();

  return (
    <Routes>
      <Route path="/" element={<Questinary />} />
      <Route
        path="/support"
        element={
          <AuthorizeItem
            key={`router:newsletter`}
            ctx={authCtx.keycloak}
            roleName={`anketa-support`}
          >
            <QuestinarySupport />
          </AuthorizeItem>
        }
      />
      <Route
        path="/newsletter"
        element={
          <AuthorizeItem
            key={`router:newsletter`}
            ctx={authCtx.keycloak}
            roleName={`anketa-newsletter-admin`}
          >
            <NewsletterComponent />
          </AuthorizeItem>
        }
      />
      <Route path="/public/event/:event/:partner" element={<EventDetection />} />
      <Route path="/public/event/:event/presentations" element={<PublicPresentations />} />
      <Route path="/public/event/:event/:partner/:presentation" element={<EventDetection />} />
      <Route
        path="/public/newsletter/:newsletter/unsubscribe/:userid"
        element={<NewsletterLeave />}
      />
      <Route
        path="/public/newsletter/:newsletter/appointment/:userid"
        element={<AppointmentComponent />}
      />
    </Routes>
  );
};
