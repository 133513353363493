import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { SupportContextType } from '../../_types/api/support';
import { useKeycloak } from '../auth';
import { useApi } from '../api';
import {
  DepartmentOption,
  OnboardingRequest,
  OnboardingRequestStatusState,
  OnboardingTemplate,
  UserValues,
} from '../../catalogs/onboarding/create/types';
import { useToast } from '../toast';
import { IReturn } from '../../_types';

const SupportContext = createContext<SupportContextType | undefined>(undefined);

export const SupportProvider = ({ children }: { children: ReactNode }) => {
  const { keycloak } = useKeycloak();
  const { Get, Post, Put } = useApi();
  const { addToast } = useToast();
  const [templates, setTemplates] = useState<OnboardingTemplate[]>([]);
  const [groups, _setGroups] = useState<DepartmentOption[]>([]);
  const [domains, _setDomains] = useState<Array<DepartmentOption>>([]);
  const [companies, _setCompanies] = useState<Array<DepartmentOption>>([]);
  const [citys, _setCitys] = useState<Array<DepartmentOption>>([]);
  const [departments, _setDepartments] = useState<Array<DepartmentOption>>([]);
  const [jobTitlet, _setJobTitlet] = useState<Array<DepartmentOption>>([]);
  const [licenses, _setLicenses] = useState<Array<DepartmentOption>>([]);
  const [verteilerlisten, _setVerteilerlisten] = useState<Array<DepartmentOption>>([]);
  const [createdUsers, _setCreatedUsers] = useState<OnboardingRequest[]>([]);
  const [trigger, setTrigger] = useState<number>(0);

  const fetchTemplates = (): Promise<OnboardingTemplate[]> => {
    return new Promise((resolve, rejcet) => {
      if (keycloak?.token) {
        Get({ path: `onboarding/template`, token: keycloak?.token })
          .then((response) => {
            if (response.status === 500) {
              console.error('Error Fetching On-Boarding Templates', response);
              setTemplates([]);
              resolve([]);
            } else if (response.status === 403) {
              console.error('Error Fetching On-Boarding Templates');
              setTemplates([]);
              resolve([]);
            } else {
              resolve(response.data.data);
              setTemplates(response.data.data);
            }
          })
          .catch((err) => {
            console.error('Error Fetching On-Boarding Templates', err);
            setTemplates([]);
            rejcet([]);
          });
      } else {
        console.error('You have to be auth by keycloak to fetch the Templates!');
        setTemplates([]);
        resolve([]);
      }
    });
  };

  const handleApproverAction = (
    user: string,
    status: string,
    type: OnboardingRequestStatusState
  ): Promise<IReturn> => {
    return new Promise((resolve, rejcet) => {
      if (keycloak?.token) {
        Post({
          path: `onboarding/user/${user}/${status}/${type}`,
          token: keycloak?.token,
        })
          .then(async (response) => {
            if (response.status === 500) {
              addToast({
                title: 'Fehler beim erstellen des Nutzeres in Entra!',
                text: <p>{JSON.stringify(response.data)}</p>,
                color: 'danger',
                iconType: 'cross',
              });
            } else {
              addToast({
                title: 'Nutzer wurde im Entra angelegt!',
                text: <p>Anfrage wurde erstellt</p>,
                color: 'success',
                iconType: 'check',
              });
            }
            setTrigger(trigger + 1);
          })
          .catch((err) => {
            rejcet({ data: err, status: 500 });
            addToast({
              title: 'Fehler beim erstellen des des Nutzeres in Entra!',
              text: <p>{err.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
          });
        setTrigger(trigger + 1);
      } else {
        console.error('You have to be auth by keycloak to create a user!');
        resolve({ data: 'You have to be auth by keycloak to create a user!', status: 403 });
      }
    });
  };

  const updateStatus = (id: string, state: OnboardingRequestStatusState): Promise<IReturn> => {
    return new Promise((resolve, rejcet) => {
      if (keycloak?.token) {
        Put({
          path: `onboarding/status/${id}/${state}`,
          token: keycloak?.token,
        })
          .then(async (response) => {
            if (response.status === 500) {
              addToast({
                title: 'Fehler beim erstellen des neuen On-Boarding Templates!',
                text: <p>{JSON.stringify(response.data)}</p>,
                color: 'danger',
                iconType: 'cross',
              });
            } else {
              addToast({
                title: 'On-Boarding Templates erstellt!',
                text: <p>Anfrage wurde erstellt</p>,
                color: 'success',
                iconType: 'check',
              });
            }
          })
          .catch((err) => {
            rejcet({ data: err, status: 500 });
            addToast({
              title: 'Fehler beim erstellen des Nutzers On-Boarding Templates!',
              text: <p>{err.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
          });
      } else {
        console.error('You have to be auth by keycloak to create a user!');
        resolve({ data: 'You have to be auth by keycloak to create a user!', status: 403 });
      }
    });
  };

  const setCompanies = (updateValue: { label: string; value: string }) => {
    _setCompanies([...companies, updateValue]);
  };

  const setCitys = (updateValue: { label: string; value: string }) => {
    _setCitys([...citys, updateValue]);
  };

  const setDepartments = (updateValue: { label: string; value: string }) => {
    _setDepartments([...departments, updateValue]);
  };

  const setJobTitlet = (updateValue: { label: string; value: string }) => {
    _setJobTitlet([...jobTitlet, updateValue]);
  };

  const fetchCreatedUsers = async (): Promise<any[]> => {
    return new Promise((resolve, rejects) => {
      Get({ path: `onboarding/users`, token: keycloak?.token })
        .then((response) => {
          if (response.status === 403 || response.status === 500) {
            _setCreatedUsers([]);
            resolve([]);
          } else {
            _setCreatedUsers(response.data.data);
            resolve(response.data.data);
          }
        })
        .catch((err) => {
          console.error('Error Fetching On-Boarding Templates', err);
          resolve([]);
        });
    });
  };

  const fetchUserById = async (userId: string): Promise<UserValues> => {
    return Get({ path: `onboarding/tenant/user/${userId}`, token: keycloak?.token })
      .then((response) => {
        if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 500 ||
          response.status === 404
        ) {
          console.log('Error Getting Tenant Values:', response);
          return {
            givenName: '',
            surname: '',
            displayName: '',
            mailNickname: '',
            mail: '',
            preferredLanguage: '',
            department: [],
            domain: [],
            companyName: [],
            city: [],
            jobTitle: [],
            groups: [],
            verteilerlisten: [],
            licenseDetails: [],
            userPrincipalName: '',
            id: '',
            usageLocation: '',
            accountEnabled: false,
          };
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        console.error('Error Getting Tenant Values:', err);
        return {
          givenName: '',
          surname: '',
          displayName: '',
          mailNickname: '',
          mail: '',
          preferredLanguage: '',
          department: [],
          domain: [],
          companyName: [],
          city: [],
          jobTitle: [],
          groups: [],
          verteilerlisten: [],
          licenseDetails: [],
          userPrincipalName: '',
          id: '',
          usageLocation: '',
          accountEnabled: false,
        };
      });
  };
  const fetchingInitData = async () => {
    Get({ path: `onboarding/tenant`, token: keycloak?.token })
      .then((response) => {
        if (response.status === 401 || response.status === 403 || response.status === 500) {
          console.log('Error Getting Tenant Values:', response);
        } else {
          const { citys, companies, departments, jobTitels, licenses, domains, groups, groupMail } =
            response.data.data;
          _setCitys(citys);
          _setCompanies(companies);
          _setDepartments(departments);
          _setJobTitlet(jobTitels);
          _setLicenses(licenses);
          _setDomains(domains);
          _setGroups(groups);
          _setVerteilerlisten(groupMail);
        }
      })
      .catch((err) => {
        console.log('Init Data: ', err);
        console.error('Error Getting Tenant Values:', err);
      });

    await fetchCreatedUsers();
    await fetchTemplates();
  };

  const deleteUser = (userId: string, action: OnboardingRequestStatusState) => {
    if (keycloak?.token) {
      Post({
        path: `onboarding/user/${userId}/delete/${action}`,
        token: keycloak?.token,
      })
        .then((response) => {
          const status = response.status;
          addToast({
            title:
              status === 500
                ? 'Fehler!'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'Warnung'
                : 'Erfolgreich',
            text: <p>{JSON.stringify(response.data)}</p>,
            color:
              status === 500
                ? 'danger'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'warning'
                : 'success',
            iconType:
              status === 500
                ? 'cross'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'alert'
                : 'check',
          });

          setTrigger(trigger + 1);
        })
        .catch((err) => {
          addToast({
            title:
              action === 'approved'
                ? 'Fehler beim Löschen des Nutzers!'
                : 'Fehler beim der Ablehung der Löschung des Nutzers!',
            text: <p>{err.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
          setTrigger(trigger + 1);
        });
    } else {
      console.error('You have to be auth by keycloak to create a user!');
    }
  };
  const createUser = (userId: string, action: OnboardingRequestStatusState) => {
    if (keycloak?.token) {
      Post({
        path: `onboarding/user/${userId}/create/${action}`,
        token: keycloak?.token,
      })
        .then((response) => {
          const status = response.status;
          addToast({
            title:
              status === 500
                ? 'Fehler!'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'Warnung'
                : 'Erfolgreich',
            text: <p>{JSON.stringify(response.data)}</p>,
            color:
              status === 500
                ? 'danger'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'warning'
                : 'success',
            iconType:
              status === 500
                ? 'cross'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'alert'
                : 'check',
          });

          setTrigger(trigger + 1);
        })
        .catch((err) => {
          addToast({
            title:
              action === 'approved'
                ? 'Fehler beim Erstellen des Nutzers!'
                : 'Fehler beim der Ablehung der Erstellung des Nutzers!',
            text: <p>{err.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
          setTrigger(trigger + 1);
        });
    } else {
      console.error('You have to be auth by keycloak to create a user!');
    }
  };
  const updateUser = (userId: string, action: OnboardingRequestStatusState) => {
    if (keycloak?.token) {
      Post({
        path: `onboarding/user/${userId}/update/${action}`,
        token: keycloak?.token,
      })
        .then((response) => {
          const status = response.status;
          addToast({
            title:
              status === 500
                ? 'Fehler!'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'Warnung'
                : 'Erfolgreich',
            text: <p>{JSON.stringify(response.data)}</p>,
            color:
              status === 500
                ? 'danger'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'warning'
                : 'success',
            iconType:
              status === 500
                ? 'cross'
                : status === 304 || status === 401 || status === 403 || status === 409
                ? 'alert'
                : 'check',
          });

          setTrigger(trigger + 1);
        })
        .catch((err) => {
          addToast({
            title:
              action === 'approved'
                ? 'Fehler beim Updaten des Nutzers'
                : 'Fehler beim dem update des Nutzers!',
            text: <p>{err.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
          setTrigger(trigger + 1);
        });
    } else {
      console.error('You have to be auth by keycloak to create a user!');
    }
  };

  const requestCreateUser = (newUser: OnboardingRequest): Promise<IReturn> => {
    return new Promise((resolve, rejcet) => {
      if (keycloak?.token) {
        Post({
          path: `onboarding/user/request/create`,
          value: newUser,
          token: keycloak?.token,
        })
          .then(async (response) => {
            if (response.status === 500) {
              addToast({
                title: 'Fehler beim erstellen des "Create" request!',
                text: <p>{JSON.stringify(response.data)}</p>,
                color: 'danger',
                iconType: 'cross',
              });
            } else {
              addToast({
                title: 'Anfrage wurde erstellt',
                text: <p></p>,
                color: 'success',
                iconType: 'check',
              });
              setTrigger(trigger + 1);
            }
          })
          .catch((err) => {
            rejcet({ data: err, status: 500 });
            addToast({
              title: 'Fehler beim erstellen des "Create" request!',
              text: <p>{err.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
            setTrigger(trigger + 1);
          });
      } else {
        console.error('You have to be auth by keycloak to create a user!');
        resolve({ data: 'You have to be auth by keycloak to create a user!', status: 403 });
      }
    });
  };

  const requestUpdateUser = (
    oldUser: OnboardingRequest,
    newUser: OnboardingRequest,
    diff: Partial<OnboardingRequest>
  ): Promise<IReturn> => {
    return new Promise((resolve, rejcet) => {
      if (keycloak?.token) {
        if (Object.keys(diff).length === 0) {
          addToast({
            title: 'Fehler beim erstellen des "Updates" request!',
            text: <p>Es wurden keine änderungen erkannt.</p>,
            color: 'warning',
            iconType: 'alert',
          });
        } else {
          Put({
            path: `onboarding/user/${oldUser.user.id}/request/update`,
            value: {
              old: oldUser,
              newUser: newUser,
              diff,
            },
            token: keycloak?.token,
          })
            .then(async (response) => {
              if (response.status === 500) {
                addToast({
                  title: 'Fehler beim erstellen des "Updates" request!',
                  text: <p>{JSON.stringify(response.data)}</p>,
                  color: 'danger',
                  iconType: 'cross',
                });
              } else {
                addToast({
                  title: 'Anfrage wurde erstellt',
                  text: <p></p>,
                  color: 'success',
                  iconType: 'check',
                });
              }
              setTrigger(trigger + 1);
              rejcet(response);
            })
            .catch((err) => {
              rejcet({ data: err, status: 500 });
              addToast({
                title: 'Fehler beim erstellen des "Create" request!',
                text: <p>{err.data}</p>,
                color: 'danger',
                iconType: 'cross',
              });
              setTrigger(trigger + 1);
            });
        }
      } else {
        console.error('You have to be auth by keycloak to create a user!');
        resolve({ data: 'You have to be auth by keycloak to create a user!', status: 403 });
      }
    });
  };

  const requestDeleteUser = (id: string): Promise<IReturn> => {
    return new Promise((resolve, rejcet) => {
      if (keycloak?.token) {
        Put({
          path: `onboarding/user/${id}/request/delete`,
          token: keycloak?.token,
        })
          .then(async (response) => {
            if (response.status === 500) {
              addToast({
                title: 'Fehler beim erstellen des "Delete" request!',
                text: <p>{JSON.stringify(response.data)}</p>,
                color: 'danger',
                iconType: 'cross',
              });
            } else {
              addToast({
                title: 'Anfrage wurde erstellt',
                text: <p>{JSON.stringify(response.data)}</p>,
                color: 'success',
                iconType: 'check',
              });
              setTrigger(trigger + 1);
            }
          })
          .catch((err) => {
            rejcet({ data: err, status: 500 });
            addToast({
              title: 'Fehler beim erstellen des "Delete" request!',
              text: <p>{err}</p>,
              color: 'danger',
              iconType: 'cross',
            });
            setTrigger(trigger + 1);
          });
      } else {
        console.error('You have to be auth by keycloak to create a user!');
        resolve({ data: 'You have to be auth by keycloak to create a user!', status: 403 });
      }
    });
  };

  useEffect(() => {
    if (keycloak?.token) {
      fetchingInitData();
    }
  }, [keycloak?.token, trigger]);

  return (
    <SupportContext.Provider
      value={{
        fetchTemplates,
        setCompanies,
        setCitys,
        setDepartments,
        fetchCreatedUsers,
        setJobTitlet,
        fetchUserById,
        updateStatus,
        handleApproverAction,
        createUser,
        updateUser,
        deleteUser,
        requestCreateUser,
        requestUpdateUser,
        requestDeleteUser,
        templates,
        jobTitlet,
        groups,
        domains,
        departments,
        citys,
        companies,
        createdUsers,
        licenses,
        verteilerlisten,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export const useSupport = () => {
  const context = useContext(SupportContext);
  if (context === undefined) {
    throw new Error('useSupport must be used within a SupportProvider');
  }
  return context;
};
