import React, { useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiDatePicker,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { useNewsletter } from '../../../_functions';
import { useToast } from '../../../_functions/toast';
import moment from 'moment';

export const CreateEvent = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
  const { events, createEvents } = useNewsletter();
  const { addToast } = useToast();

  const [name, setName] = useState<string | undefined>(undefined);

  const closeModal = () => {
    setName(undefined);
    handleClose();
  };

  const finish = () => {
    if (name) {
      const partnerAlreadyAssign = events.find((e) => e.event.name === name);
      if (partnerAlreadyAssign) {
        addToast({
          title: 'Keine Dupletten',
          text: <p>Das Event existiert bereits!</p>,
          color: 'warning',
          iconType: 'cross',
        });
      } else {
        createEvents(name);
      }
    }
    closeModal();
  };

  return (
    <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Neues Event erstellen</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow label="Name" helpText="Name des Events">
            <EuiFieldText
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
        <EuiButton disabled={name === undefined} onClick={finish}>
          Erstellen
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export const AssignPartnerEvent = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
  const { events, partners, assignPartnerToEvent } = useNewsletter();

  const [event, setEvents] = useState<Array<EuiComboBoxOptionOption<string>>>([]);
  const [partner, setPartner] = useState<Array<EuiComboBoxOptionOption<string>>>([]);

  const closeModal = () => {
    handleClose();
  };

  const finish = () => {
    if (event[0] && event[0].value) {
      partner.forEach((p) => {
        if (event[0]?.value && p.value) {
          assignPartnerToEvent(event[0]?.value, p.value);
        }
      });
    }
    closeModal();
  };
  const onEventChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    if (options.length > 0) {
      setEvents(options);
    }
  };

  const onPartnerChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    setPartner(options);
  };

  return (
    <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Partners ein Event zuweisen</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow label="Event">
            <EuiComboBox
              placeholder="Select a Event"
              options={events.map((p) => ({
                label: p.event.name,
                value: p.event.id,
              }))}
              onChange={onEventChange}
              selectedOptions={event}
              singleSelection={{ asPlainText: true }}
              isClearable={true}
            />
          </EuiFormRow>

          <EuiFormRow label="Partner">
            <EuiComboBox
              placeholder="Select Partners"
              isDisabled={event.length === 0}
              options={partners
                .filter((p) => {
                  const eventId = event[0]?.value;
                  if (eventId) {
                    return !p.events.some((event) => event.id === eventId);
                  }
                  return true;
                })
                .map((p) => ({
                  label: p.companie.name,
                  value: p.companie.id,
                }))}
              onChange={onPartnerChange}
              selectedOptions={partner}
              isClearable={true}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
        <EuiButton disabled={event.length === 0 || partner.length === 0} onClick={finish}>
          Erstellen
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export const CreateEventPresentation = ({
  handleClose,
}: {
  handleClose: () => void;
}): JSX.Element => {
  const { events, partners, createEventPresentation } = useNewsletter();

  const [event, setEvents] = useState<Array<EuiComboBoxOptionOption<string>>>([]);
  const [title, setTitle] = useState<string>('');
  const [partner, setPartner] = useState<Array<EuiComboBoxOptionOption<string>>>([]);
  const [startDate, setStartDate] = useState(moment());

  const handleChange = (date: moment.Moment) => {
    setStartDate(date);
  };
  const onPartnerChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    setPartner(options);
  };
  const closeModal = () => {
    setTitle('');
    setPartner([]);
    handleClose();
    setEvents([]);
  };

  const finish = () => {
    if (event[0].value && partner[0].value) {
      createEventPresentation(event[0].value, title, startDate.toISOString(), partner[0].value);
      closeModal();
    }
  };
  const onEventChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    if (options.length > 0) {
      setEvents(options);
    }
  };

  return (
    <EuiModal onClose={closeModal} initialFocus="[name=sendAs]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Präsentation erstellen</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow label="Name" helpText="Titel">
            <EuiFieldText
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </EuiFormRow>
          <EuiFormRow label="Event">
            <EuiComboBox
              placeholder="Select a Event"
              options={events.map((p) => ({
                label: p.event.name,
                value: p.event.id,
              }))}
              onChange={onEventChange}
              selectedOptions={event}
              singleSelection={{ asPlainText: true }}
              isClearable={true}
            />
          </EuiFormRow>

          <EuiFormRow label="Termin">
            <EuiDatePicker showTimeSelect selected={startDate} onChange={handleChange} />
          </EuiFormRow>
          <EuiFormRow label="Partner">
            <EuiComboBox
              placeholder="Select Partners"
              isDisabled={event.length === 0}
              options={partners.map((p) => ({
                label: p.companie.name,
                value: p.companie.id,
              }))}
              singleSelection={{ asPlainText: true }}
              onChange={onPartnerChange}
              selectedOptions={partner}
              isClearable={true}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
        <EuiButton
          disabled={event.length === 0 || !title.trim() || partner.length === 0}
          onClick={finish}
        >
          Erstellen
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
