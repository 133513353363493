import React, { useState } from 'react';
import { OpsTable } from '../../components/tabel';
import { getSubscriptionColor, useNewsletter } from '../../_functions';
import { NewsletterDetails, UserDetail } from '../../_types';
import { EuiBasicTableColumn, EuiHealth } from '@elastic/eui';
import { EditNewsletterModal, NewsletterPreviewModal } from '../modal';
import { DeleteNewsletterModal } from '../modal/newsletter/delete';

export const NewsletterTable = (): JSX.Element => {
  const { newsletters } = useNewsletter();

  const [selectedNewsletter, setSelectedNewsletter] = useState<NewsletterDetails>();
  const [modal, setModal] = useState<'edit' | 'delete' | 'preview' | 'none'>();

  const columnsNewsletter: EuiBasicTableColumn<NewsletterDetails>[] = [
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      truncateText: true,
      render: (name: string) => {
        return <div style={{ minWidth: '100px' }}>{name}</div>;
      },
    },
    {
      field: 'sender',
      name: 'E-Mail wird gesendet von',
      sortable: true,
      truncateText: true,
      render: (sender: any, item) => {
        console.warn(sender, item);
        return <div style={{ minWidth: '100px' }}>{sender}</div>;
      },
    },
    {
      field: 'from_email',
      name: 'Weiterleitung von',
      sortable: true,
      truncateText: true,
      render: (from: string) => {
        return <div style={{ minWidth: '100px' }}>{from}</div>;
      },
    },
    {
      field: 'interval',
      name: 'Job läuft alle',
      sortable: true,
      truncateText: true,
      render: (interval: string) => {
        return <div style={{ minWidth: '100px' }}>{interval} Minuten</div>;
      },
    },
    {
      field: 'text',
      name: 'Content',
      sortable: true,
      truncateText: true,
      render: (text: string) => {
        return <div style={{ minWidth: '100px' }} dangerouslySetInnerHTML={{ __html: text }} />;
      },
    },
    {
      field: 'users',
      name: 'Abonenten',
      sortable: true,
      truncateText: true,
      render: (users: UserDetail[], newsletter) => {
        const activeUsers = users?.filter((usr) =>
          usr.newsletters?.filter((news) => news.id === newsletter.id && news.subscriber === true)
        ).length;
        const hundert = users.some((usr) => usr.company.id === null) ? 0 : users.length;
        const percent: number = (activeUsers / hundert) * 100;
        console.warn(users, hundert);
        const color = getSubscriptionColor(percent);
        return (
          <EuiHealth color={color}>
            {activeUsers} / {hundert}
          </EuiHealth>
        );
      },
    },
    {
      field: 'action',
      name: '',
      actions: [
        {
          name: 'Pre-View',
          description: 'Zeigt dir an wie die E-Mail Beispielhaft ausschaut',
          type: 'icon',
          icon: 'eye',
          onClick: (item: NewsletterDetails) => {
            setSelectedNewsletter(item);
            setModal('preview');
          },
        },
        {
          name: 'Editieren',
          description: 'Element bearbeiten',
          type: 'icon',
          icon: 'pencil',
          onClick: (item: NewsletterDetails) => {
            setSelectedNewsletter(item);
            setModal('edit');
          },
        },
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: NewsletterDetails) => {
            setSelectedNewsletter(item);
            setModal('delete');
          },
        },
      ],
      width: '100px',
    },
  ];

  const handleClose = () => {
    setModal('none');
  };

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={newsletters}
        compressed={true}
        columns={columnsNewsletter}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsStorageKey="newsletter:full"
        opsSearch={true}
        opsFilterColumns={['from_email', 'interval', 'name', 'sender', 'text']}
      />
      {modal === 'edit' && selectedNewsletter && (
        <EditNewsletterModal selectedNewsletter={selectedNewsletter} handleClose={handleClose} />
      )}
      {modal === 'delete' && selectedNewsletter && (
        <DeleteNewsletterModal selectedNewsletter={selectedNewsletter} handleClose={handleClose} />
      )}
      {modal === 'preview' && selectedNewsletter && (
        <NewsletterPreviewModal selectedNewsletter={selectedNewsletter} handleClose={handleClose} />
      )}
    </>
  );
};
