import React, { useState } from 'react';
import { OpsTable } from '../../components/tabel';
import { url, useNewsletter } from '../../_functions';
import { BasicUser, Companie, Events, Presentation } from '../../_types';
import {
  EuiBadge,
  EuiBasicTableColumn,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiTabbedContent,
  EuiText,
  EuiToolTip,
} from '@elastic/eui';
import { useToast } from '../../_functions/toast';
import moment from 'moment';
import { DeleteEventModal } from '../modal/user/deleteEvent';
import { TooltipGrid } from '../../catalogs/onboarding/table/change';
import { EventFlyout } from '../flyout/event';
import { UI_URL } from '../../_functions/common';

export const EventTableTab = (): JSX.Element => {
  const [selectedTabId, setSelectedTabId] = useState<string>('stand');

  const tabs = [
    {
      id: 'stand--id',
      name: 'Event',
      content: <EventTable />,
    },
    {
      id: 'präsentation--id',
      name: 'Präsentation',
      content: <EventPresentationTable />,
    },
  ];
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiTabbedContent
          tabs={tabs}
          initialSelectedTab={tabs[0]}
          autoFocus="selected"
          onTabClick={(tab) => {
            setSelectedTabId(tab.id);
            console.log('clicked tab', tab);
          }}
        />
      </EuiPageBody>
    </EuiPage>
  );
};

export const EventTable = (): JSX.Element => {
  const { events } = useNewsletter();
  const { addToast } = useToast();
  const [selectedEvent, setSelectedEvent] = useState<Events | undefined>(undefined);
  const [modal, setModal] = useState<'delete' | 'none'>('none');
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false);

  const handleClose = () => {
    setModal('none');
    setIsFlyoutVisible(false);
    setSelectedEvent(undefined);
  };

  const columnsNewsletter: EuiBasicTableColumn<Events>[] = [
    {
      field: 'event',
      name: 'Event',
      sortable: true,
      truncateText: true,
      render: (event: Companie, record: Events) => {
        return (
          <EuiText
            onClick={() => {
              setIsFlyoutVisible(true);
              setSelectedEvent(record);
            }}
          >
            <p>{event.name}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'guests',
      name: 'Gäste',
      width: '25%',
      sortable: true,
      truncateText: true,
      render: (guests: BasicUser[], record: Events) => {
        const maxBadges: number = 10;
        let badgeCount: number = 0;
        const badges: JSX.Element[] = [];
        if (guests.length === 0) {
          return (
            <EuiText>
              <p>Keine Gäste bei diesem Event</p>
            </EuiText>
          );
        } else {
          guests.map((e) => {
            badgeCount++;
            if (badgeCount <= maxBadges) {
              badges.push(
                <EuiToolTip
                  key={e.id}
                  content={
                    <EuiFlexGrid direction="column">
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Vorname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.firstname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Nachname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.lastname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  }
                >
                  <EuiBadge style={{ margin: '5px' }}>{e.email}</EuiBadge>
                </EuiToolTip>
              );
            } else {
              if (badgeCount === guests.length) {
                badges.push(
                  <EuiBadge iconType="plus" style={{ margin: '5px' }}>
                    {badgeCount}
                  </EuiBadge>
                );
              }
            }
          });
          return (
            <div
              key={`${record.event.id}:table:guets:overflow`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {badges}
            </div>
          );
        }
      },
    },
    {
      field: 'partner',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (partner: Companie[], record: Events) => {
        if (partner.length === 0) {
          return (
            <EuiText>
              <p>Keine Partner auf dem Event</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {partner.map((p, idx) => {
                return (
                  <EuiBadge key={`event:table:partner:${p.id}:${idx}`} style={{ margin: '5px' }}>
                    <p
                      onClick={() => {
                        const textToCopy = `${UI_URL}public/event/${record.event.id}/${p.id}`;

                        navigator.clipboard
                          .writeText(textToCopy)
                          .then(() => {
                            console.log('Copied to clipboard:', textToCopy);
                            addToast({
                              title: 'Link Kopiert!',
                              text: (
                                <p>
                                  Der Link für {p.name} für das {record.event.name} wurde kopiert!
                                </p>
                              ),
                              color: 'success',
                              iconType: 'check',
                            });
                          })
                          .catch((err) => {
                            console.error('Failed to copy to clipboard:', err);
                            addToast({
                              title: 'Fehler beim Kopieren des Links!',
                              text: <p>{JSON.stringify(err)}</p>,
                              color: 'danger',
                              iconType: 'cross',
                            });
                          });
                      }}
                    >
                      {p.name}
                    </p>
                  </EuiBadge>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      field: 'presentations',
      name: 'Presentations',
      sortable: true,
      truncateText: true,
      render: (presentations: Presentation[], record: Events) => {
        if (presentations.length === 0) {
          return (
            <EuiText>
              <p>Keine Presentation für das Event</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
              onClick={() => {
                const textToCopy = `${UI_URL}public/event/${record.event.id}/presentations`;

                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    console.log('Copied to clipboard:', textToCopy);
                    addToast({
                      title: 'Link Kopiert!',
                      text: (
                        <p>
                          Der Link für die Präsentationsliste für das {record.event.name} wurde
                          kopiert!
                        </p>
                      ),
                      color: 'success',
                      iconType: 'check',
                    });
                  })
                  .catch((err) => {
                    console.error('Failed to copy to clipboard:', err);
                    addToast({
                      title: 'Fehler beim Kopieren des Links!',
                      text: <p>{JSON.stringify(err)}</p>,
                      color: 'danger',
                      iconType: 'cross',
                    });
                  });
              }}
            >
              {presentations.map((p) => {
                return (
                  <EuiToolTip
                    content={
                      <>
                        <TooltipGrid title="Partner" content={p.partner.name} />
                        <TooltipGrid title="Termin" content={moment(p.time).format('lll')} />
                        <TooltipGrid title="Anazhl an Gästen" content={`${p.users.length}`} />
                      </>
                    }
                  >
                    <EuiBadge style={{ margin: '5px' }}>
                      <p>{p.title}</p>
                    </EuiBadge>
                  </EuiToolTip>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      field: 'action',
      name: '',
      actions: [
        {
          name: 'Pre-View',
          description: 'Event Details',
          type: 'icon',
          icon: 'eye',
          onClick: (item: Events) => {
            console.log('Event', item);
            setIsFlyoutVisible(true);
            setSelectedEvent(item);
          },
        },
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: Events) => {
            console.log('Events', item);
            setSelectedEvent(item);
            setModal('delete');
          },
        },
      ],
      width: '100px',
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={events as any}
        compressed={true}
        columns={columnsNewsletter}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsFilterColumns={['guests', 'partner']}
        opsSearch
        opsStorageKey="event"
      />
      {modal === 'delete' && selectedEvent && (
        <DeleteEventModal handleClose={handleClose} selectedEvent={selectedEvent} />
      )}
      {isFlyoutVisible && selectedEvent !== undefined && (
        <EventFlyout event={selectedEvent} onClose={handleClose} />
      )}
    </>
  );
};

export const EventPresentationTable = (): JSX.Element => {
  const { presentations } = useNewsletter();
  const { addToast } = useToast();
  const columnsNewsletter: EuiBasicTableColumn<Presentation>[] = [
    {
      field: 'event',
      name: 'Event',
      sortable: true,
      truncateText: true,
      render: (event: Companie) => {
        return (
          <EuiText>
            <p>{event.name}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'time',
      name: 'Time',
      sortable: true,
      truncateText: true,
      render: (time: string) => {
        return (
          <EuiText>
            <p>{moment(time).format('lll')}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'users',
      name: 'Gäste',
      width: '50%',
      sortable: true,
      truncateText: true,
      render: (users: BasicUser[]) => {
        if (users.length === 0) {
          return (
            <EuiText>
              <p>Keine Gäste bei diesem Event</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {users.map((e) => (
                <EuiToolTip
                  key={e.id}
                  content={
                    <EuiFlexGrid direction="column">
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Vorname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.firstname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Nachname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.lastname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  }
                >
                  <EuiBadge style={{ margin: '5px' }}>{e.email}</EuiBadge>
                </EuiToolTip>
              ))}
            </div>
          );
        }
      },
    },
    {
      field: 'partner',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (partner: Companie, record: Presentation) => {
        return (
          <EuiBadge style={{ margin: '5px' }}>
            <p
              onClick={() => {
                const textToCopy = `${UI_URL}public/event/${record.event.id}/${partner.id}/${record.id}`;

                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    console.log('Copied to clipboard:', textToCopy);
                    addToast({
                      title: 'Link Kopiert!',
                      text: (
                        <p>
                          Der Link für {partner.name} für das {record.event.name} wurde kopiert!
                        </p>
                      ),
                      color: 'success',
                      iconType: 'check',
                    });
                  })
                  .catch((err) => {
                    console.error('Failed to copy to clipboard:', err);
                    addToast({
                      title: 'Fehler beim Kopieren des Links!',
                      text: <p>{JSON.stringify(err)}</p>,
                      color: 'danger',
                      iconType: 'cross',
                    });
                  });
              }}
            >
              {partner.name}
            </p>
          </EuiBadge>
        );
      },
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={presentations as any}
        compressed={true}
        columns={columnsNewsletter}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsFilterColumns={['partner', 'time', 'users', 'event']}
        opsSearch
        opsStorageKey="event:presentations"
      />
    </>
  );
};

export const EventFlyoutPresentationTable = ({
  presentations,
}: {
  presentations: Presentation[];
}): JSX.Element => {
  const { addToast } = useToast();
  const columnsNewsletter: EuiBasicTableColumn<Presentation>[] = [
    {
      field: 'event',
      name: 'Event',
      sortable: true,
      truncateText: true,
      render: (event: Companie) => {
        return (
          <EuiText>
            <p>{event.name}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'time',
      name: 'Time',
      sortable: true,
      truncateText: true,
      render: (time: string) => {
        return (
          <EuiText>
            <p>{moment(time).format('lll')}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'users',
      name: 'Gäste',
      sortable: true,
      width: '250px',
      truncateText: true,
      render: (users: BasicUser[]) => {
        if (users.length === 0) {
          return (
            <EuiText>
              <p>Keine Gäste bei diesem Event</p>
            </EuiText>
          );
        } else {
          return (
            <div>
              {users.map((e) => (
                <EuiToolTip
                  key={e.id}
                  content={
                    <EuiFlexGrid direction="column">
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Vorname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.firstname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Nachname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.lastname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  }
                >
                  <EuiBadge style={{ margin: '5px' }}>{e.email}</EuiBadge>
                </EuiToolTip>
              ))}
            </div>
          );
        }
      },
    },
    {
      field: 'partner',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (partner: Companie, record: Presentation) => {
        return (
          <EuiBadge style={{ margin: '5px' }}>
            <p
              onClick={() => {
                const textToCopy = `${UI_URL}public/event/${record.event.id}/${partner.id}/${record.id}`;

                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    console.log('Copied to clipboard:', textToCopy);
                    addToast({
                      title: 'Link Kopiert!',
                      text: (
                        <p>
                          Der Link für {partner.name} für das {record.event.name} wurde kopiert!
                        </p>
                      ),
                      color: 'success',
                      iconType: 'check',
                    });
                  })
                  .catch((err) => {
                    console.error('Failed to copy to clipboard:', err);
                    addToast({
                      title: 'Fehler beim Kopieren des Links!',
                      text: <p>{JSON.stringify(err)}</p>,
                      color: 'danger',
                      iconType: 'cross',
                    });
                  });
              }}
            >
              {partner.name}
            </p>
          </EuiBadge>
        );
      },
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={presentations as any}
        compressed={true}
        columns={columnsNewsletter}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsFilterColumns={['partner', 'time', 'users']}
        opsStorageKey="event:flyout"
        opsSearch
      />
    </>
  );
};
