import React, { useState } from 'react';
import {
  EuiFlexGroup,
  EuiPage,
  EuiFlexItem,
  EuiPageBody,
  EuiPageHeader,
  EuiPageContent,
  EuiPageContentBody,
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiTitle,
} from '@elastic/eui';
import { Avatar } from '../components/avatar';
import { AuthorizeItem, useKeycloak } from '../_functions';
import { AssignNewsletterToUserModal, CreateNewsletterModal, CreateUserModal } from './modal';
import { NewsletterTable, UserTable } from './tables';
import { CreatePartner, CreatePartnerUser } from './modal/user/partner';
import { PartnerTable } from './tables/partner';
import { AssignPartnerEvent, CreateEvent, CreateEventPresentation } from './modal/user/evnts';
import { EventTable, EventTableTab } from './tables/events';

export const NewsletterComponent = (): JSX.Element => {
  const authCtx = useKeycloak();

  const [view, setView] = useState<'user' | 'newsletter' | 'partner' | 'events'>('user');
  const [modal, setModal] = useState<
    | 'createUser'
    | 'createNewsletter'
    | 'assign'
    | 'createParter'
    | 'createParterUser'
    | 'createEvent'
    | 'assignPartnerToEvent'
    | 'event-presentation'
    | undefined
  >(undefined);

  const handleClose = () => {
    setModal(undefined);
  };

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader
          pageTitle="Newsletter Admin Center"
          rightSideItems={[
            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiFlexItem>
                <Avatar />
              </EuiFlexItem>
            </EuiFlexGroup>,
          ]}
        />
        <EuiPageContent
          style={{
            overflowY: 'auto',
            height: '90vh',
            background: 'none',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <EuiFlexGroup wrap>
            <AuthorizeItem
              key={`newsletter:user:auth`}
              ctx={authCtx.keycloak}
              roleName={`anketa-newsletter-admin`}
            >
              <EuiFlexItem key="newsletter:user">
                <EuiCard
                  icon={<EuiIcon type={'user'} />}
                  title="User"
                  description="Zeige alle Nutzer und deren Abos an."
                  onClick={() => {
                    setView('user');
                  }}
                />
              </EuiFlexItem>
            </AuthorizeItem>
            <AuthorizeItem
              key={`newsletter:newsletter:auth`}
              ctx={authCtx.keycloak}
              roleName={`anketa-newsletter-admin`}
            >
              <EuiFlexItem key="newsletter:newsletter">
                <EuiCard
                  icon={<EuiIcon type={'email'} />}
                  title="Newsletter"
                  description="Zeige dir alle Newsletter an."
                  onClick={() => {
                    setView('newsletter');
                  }}
                />
              </EuiFlexItem>
            </AuthorizeItem>
            <AuthorizeItem
              key={`newsletter:partner:auth`}
              ctx={authCtx.keycloak}
              roleName={`anketa-newsletter-admin`}
            >
              <EuiFlexItem key="newsletter:user">
                <EuiCard
                  icon={<EuiIcon type={'users'} />}
                  title="Partner"
                  description="Zeige alle Partner an."
                  onClick={() => {
                    setView('partner');
                  }}
                />
              </EuiFlexItem>
            </AuthorizeItem>
            <AuthorizeItem
              key={`newsletter:events:auth`}
              ctx={authCtx.keycloak}
              roleName={`anketa-newsletter-admin`}
            >
              <EuiFlexItem key="newsletter:events">
                <EuiCard
                  icon={<EuiIcon type={'calendar'} />}
                  title="Events"
                  description="Zeige alle Events an."
                  onClick={() => {
                    setView('events');
                  }}
                />
              </EuiFlexItem>
            </AuthorizeItem>
          </EuiFlexGroup>
          <EuiFlexGroup wrap direction="column">
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <EuiFlexGroup direction="column">
                    <EuiFlexItem>
                      <EuiTitle>
                        <h3>Newsletter</h3>
                      </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexGroup direction="row" wrap>
                        <EuiFlexItem>
                          <EuiButton
                            style={{ width: '200px' }}
                            iconType="plus"
                            fill
                            onClick={() => setModal('createUser')}
                          >
                            Nutzer anlegen
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiButton
                            style={{ width: '200px' }}
                            iconType="plus"
                            fill
                            onClick={() => setModal('createNewsletter')}
                          >
                            Newsletter erstellen
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiButton
                            style={{ width: '200px' }}
                            iconType="user"
                            fill
                            onClick={() => setModal('assign')}
                          >
                            Newsletter abonnieren
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <EuiTitle>
                    <h3>Events</h3>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup direction="row" wrap>
                    <EuiFlexItem>
                      <EuiButton
                        style={{ width: '200px' }}
                        iconType="plus"
                        fill
                        onClick={() => setModal('createParter')}
                      >
                        Partner anlegen
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButton
                        style={{ width: '200px' }}
                        iconType="plus"
                        fill
                        onClick={() => setModal('createParterUser')}
                      >
                        Partner Nutzer anlegen
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButton
                        style={{ width: '200px' }}
                        iconType="plus"
                        fill
                        onClick={() => setModal('createEvent')}
                      >
                        Event anlegen
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButton
                        style={{ width: '200px' }}
                        iconType="plus"
                        fill
                        onClick={() => setModal('assignPartnerToEvent')}
                      >
                        Partner einem Event zuweisen
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButton
                        style={{ width: '200px' }}
                        iconType="plus"
                        fill
                        onClick={() => setModal('event-presentation')}
                      >
                        Erstelle eine Präsenation für ein Event
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer />
          <EuiPageContentBody>
            {modal === 'createUser' && <CreateUserModal handleClose={handleClose} />}
            {modal === 'createNewsletter' && <CreateNewsletterModal handleClose={handleClose} />}
            {modal === 'assign' && <AssignNewsletterToUserModal handleClose={handleClose} />}
            {modal === 'createParter' && <CreatePartner handleClose={handleClose} />}
            {modal === 'createParterUser' && <CreatePartnerUser handleClose={handleClose} />}
            {modal === 'createEvent' && <CreateEvent handleClose={handleClose} />}
            {modal === 'assignPartnerToEvent' && <AssignPartnerEvent handleClose={handleClose} />}
            {modal === 'event-presentation' && (
              <CreateEventPresentation handleClose={handleClose} />
            )}
            {view === 'user' && <UserTable />}
            {view === 'newsletter' && <NewsletterTable />}
            {view === 'partner' && <PartnerTable />}
            {view === 'events' && <EventTableTab />}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};
