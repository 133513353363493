import React, { useEffect, useState } from 'react';
import { OpsTable } from '../components/tabel';
import {
  EuiBasicTableColumn,
  EuiEmptyPrompt,
  EuiLink,
  EuiLoadingContent,
  EuiText,
} from '@elastic/eui';
import { PresentationPublic } from '../_types';
import moment from 'moment';
import { useNewsletter } from '../_functions';
import { useParams } from 'react-router';
import { UI_URL } from '../_functions/common';

export const PublicPresentations = (): JSX.Element => {
  const { getPublicPresentations } = useNewsletter();
  const { event } = useParams();

  const [presentations, setPresentations] = useState<PresentationPublic[]>([]);

  useEffect(() => {
    if (event) {
      getPublicPresentations(event)
        .then((presentation) => {
          console.log('Public Presentetions', presentation);
          setPresentations(presentation);
        })
        .catch((err) => {
          console.error(`Error Fetching Presentetions for the Events ${event}`);
        });
    }
  }, [event]);

  const columnsNewsletter: EuiBasicTableColumn<PresentationPublic>[] = [
    {
      field: 'partnerId',
      name: 'Link',
      sortable: true,
      truncateText: true,
      render: (partnerId: string, record: PresentationPublic) => {
        return (
          <EuiText>
            <EuiLink
              external
              href={`${UI_URL}public/event/${event}/${partnerId}/${record.id}`}
              color="success"
            >
              <p>{record.title}</p>
            </EuiLink>
          </EuiText>
        );
      },
    },
    {
      field: 'time',
      name: 'Time',
      sortable: true,
      truncateText: true,
      render: (time: string) => {
        return (
          <EuiText>
            <p>{moment(time).format('lll')}</p>
          </EuiText>
        );
      },
    },
  ];
  if (event === undefined || presentations.length === 0) {
    return (
      <EuiEmptyPrompt
        iconType="editorStrike"
        title={<h2>Keine Daten gefunden</h2>}
        titleSize="xs"
        body={<EuiLoadingContent />}
      />
    );
  } else {
    return (
      <>
        <OpsTable
          tableLayout="auto"
          responsive={true}
          items={presentations}
          compressed={true}
          columns={columnsNewsletter}
          sorting={true}
          pagination={{
            pageSizeOptions: [10, 25, 50, 100, 250],
            initialPageSize: 10,
          }}
          opsSearch={false}
        />
      </>
    );
  }
};
