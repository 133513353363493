import { EuiConfirmModal, EuiTitle } from '@elastic/eui';
import { useNewsletter } from '../../../_functions';
import { Partners } from '../../../_types';

export const DeletePartnerModal = ({
  selectedUser: selectedPartner,
  handleClose,
}: {
  selectedUser: Partners;
  handleClose: () => void;
}): JSX.Element => {
  const { deletePartner } = useNewsletter();

  const finish = () => {
    deletePartner(selectedPartner.companie.id);
    handleClose();
  };
  return (
    <EuiConfirmModal
      title={`Partner ${selectedPartner.companie.name} Löschen`}
      onCancel={() => {
        handleClose();
      }}
      onConfirm={() => finish()}
      cancelButtonText="Abrechen"
      confirmButtonText={`${selectedPartner.companie.name} löschen`}
      buttonColor="danger"
      defaultFocusedButton="confirm"
    >
      <p>
        Sind Sie sich sicher, dass Sie den Partner {selectedPartner.companie.name} löschen wollen?
      </p>
      <p>Es werden ausserdem folgende Refetenzen gelöscht:</p>
      {selectedPartner.employees.length > 0 && (
        <>
          <EuiTitle>
            <h3>Nutzer</h3>
          </EuiTitle>
          <ul>
            {selectedPartner.employees.map((e) => {
              return (
                <li>
                  {e.firstname} {e.lastname}({e.email})
                </li>
              );
            })}
          </ul>
        </>
      )}
      {selectedPartner.events.length > 0 && (
        <>
          <EuiTitle>
            <h3>Events</h3>
          </EuiTitle>
          <ul>
            {selectedPartner.events.map((e) => {
              return <li>{e.name}</li>;
            })}
          </ul>
        </>
      )}
    </EuiConfirmModal>
  );
};
