import React, { useContext, useEffect, useState } from 'react';
import {
  EuiFlexItem,
  EuiCard,
  EuiFlexGroup,
  EuiInMemoryTable,
  EuiPanel,
  EuiBadge,
  EuiSpacer,
  EuiHealth,
  EuiBasicTableColumn,
  EuiImage,
} from '@elastic/eui';
import { useKeycloak } from '../../_functions/auth/keycloak';
import { AnketaContext } from '../context_provider';
import { AuthorizeComponent, AuthorizeItem } from '../../_functions/auth/authorize';
import NewQuestinary from '../../helper/create_questionary';
import { Translation } from 'anketa-core';
import { useApi } from '../../_functions/api/api';
import MedoLogo from '../../asstes/images/medo';
import { useNavigate } from 'react-router';
import { CatalogButtonsProps, CatalogTypes, questionarieParams } from '../../_types';
import { useToast } from '../../_functions/toast';

export const NoCatalogSelected = ({
  setCatalogType,
  catalogType,
  items,
  buttons,
  setCatalog,
  children,
  createNewCatalog,
  lastChangeMsg,
}: {
  setCatalogType: (type: CatalogTypes) => void;
  catalogType: CatalogTypes | undefined;
  items: questionarieParams[];
  buttons: CatalogButtonsProps[];
  setCatalog: (catlogId: string) => void;
  children: React.ReactNode | undefined;
  createNewCatalog: (catalogId: CatalogTypes, name: string) => void;
  lastChangeMsg: string;
}): JSX.Element => {
  const authCtx = useKeycloak();
  const ctx = useContext(AnketaContext);
  const { Delete } = useApi();
  const { addToast } = useToast();

  const navigate = useNavigate();
  const [trigger, setTrigger] = useState<number>(0);
  const [view, setView] = useState<string>('table');

  const deleteItem = (itemId: string) => {
    if (AuthorizeComponent(authCtx.keycloak, 'anketa-admin')) {
      Delete({
        path: `questionaries/catalog/${itemId.split(':')[0]}/${itemId.split(':')[1]}`,
        token: authCtx.keycloak?.token,
      })
        .then((response) => {
          if (response.status === 500) {
            console.warn(`${itemId} cant delete the error is: ${response.data}`);
            addToast({
              title: 'Deleted Failed',
              text: response.data,
              color: 'danger',
              iconType: 'cross',
            });
            setTrigger(trigger + 1);
          } else {
            addToast({
              title: 'Deleted succsess',
              text: `Der Catalog ${itemId} wurde gelöscht, ggf. müssen sie die seite neu laden!`,
              color: 'success',
              iconType: 'check',
            });
            setCatalogType('audit');
            setTrigger(trigger + 1);
          }
        })
        .catch((err) => {
          console.error(err);
          addToast({
            title: 'Deleted Failed',
            text: err,
            color: 'danger',
            iconType: 'cross',
          });
          setTrigger(trigger + 1);
        });
    }
  };

  const editItem = (itemId: string) => {
    setCatalog(itemId);
    setView('edit');
    setCatalogType(itemId.split(':')[0] as CatalogTypes);
  };

  const cardNodes = buttons.map((item: CatalogButtonsProps) => {
    return (
      <AuthorizeItem
        key={`${item.id}:ui:auth`}
        ctx={authCtx.keycloak}
        roleName={`anketa-${item.id}`}
      >
        <EuiFlexItem key={item.id}>
          <EuiCard
            icon={
              <EuiImage
                alt={ctx.i18nContext.render(item.label) || ''}
                src={item.iconType}
                style={{ objectFit: 'contain', width: '250px', height: '100px' }}
              />
            }
            title={ctx.i18nContext.render(item.label) || ''}
            description={ctx.i18nContext.render(item.description)}
            selectable={{
              onClick: () => {
                setCatalogType(item.id);
                console.log({ item: item.id, ctx: ctx.catalog.id });
              },
              isSelected: catalogType === item.id,
            }}
            onClick={() => {
              console.log(item.id);
              setCatalogType(item.id);
              setView('table');
            }}
            footer={
              <AuthorizeItem
                key={`${item.id}:ui:auth`}
                ctx={authCtx.keycloak}
                roleName={`anketa-${item.id}-admin`}
              >
                {item.id !== 'event' && (
                  <NewQuestinary
                    catalogType={item.id}
                    updateCatalog={createNewCatalog}
                    title={ctx.i18nContext.render([
                      new Translation('en', 'Create'),
                      new Translation('de', 'Erstellen'),
                    ])}
                  />
                )}
              </AuthorizeItem>
            }
          />
        </EuiFlexItem>
      </AuthorizeItem>
    );
  });

  const columns: EuiBasicTableColumn<questionarieParams>[] = [
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      render: (value: string) => {
        return <div style={{ minWidth: '100px' }}>{value}</div>;
      },
    },
    {
      field: 'content',
      name: 'Inhalt',
      render: (content: questionarieParams) => {
        const totalKeys = Object.keys(content).length;
        const filledKeys = Object.values(content).filter((value) => value !== null).length;
        const percentageFilled = (filledKeys / totalKeys) * 100;

        let color: string;
        if (percentageFilled <= 33) {
          color = 'danger';
        } else if (percentageFilled <= 67) {
          color = 'warning';
        } else {
          color = 'success';
        }

        return <EuiHealth color={color}>{`${filledKeys}/${totalKeys}`}</EuiHealth>;
      },
    },
    {
      field: 'catalog',
      name: 'Katalog',
      sortable: true,
      render: (category: string) => {
        return <EuiBadge style={{ margin: '5px' }}>{category}</EuiBadge>;
      },
    },
    {
      name: '',
      actions: [
        {
          name: 'Editieren',
          description: 'Element bearbeiten',
          type: 'icon',
          icon: 'pencil',
          onClick: (item: questionarieParams) => {
            editItem(item.id);
          },
        },
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: questionarieParams) => {
            deleteItem(item.id);
          },
        },
      ],
      width: '100px',
    },
  ];

  return (
    <>
      <EuiFlexGroup wrap>
        {cardNodes}
        <AuthorizeItem
          key={`newsletter:ui:auth`}
          ctx={authCtx.keycloak}
          roleName={`anketa-newsletter`}
        >
          <EuiFlexItem>
            <EuiCard
              icon={<MedoLogo style={{ objectFit: 'contain', width: '250px', height: '100px' }} />}
              title="Newsletter"
              description="Gelange zur medocino Newsletter Oberfläche"
              onClick={() => {
                navigate('/newsletter');
              }}
            ></EuiCard>
          </EuiFlexItem>
        </AuthorizeItem>
        <AuthorizeItem key={`support:ui:auth`} ctx={authCtx.keycloak} roleName={`anketa-support`}>
          <EuiFlexItem>
            <EuiCard
              icon={<MedoLogo style={{ objectFit: 'contain', width: '250px', height: '100px' }} />}
              title="Support"
              description="Gelange zur medocino Support Oberfläche"
              onClick={() => {
                navigate('/support');
              }}
            ></EuiCard>
          </EuiFlexItem>
        </AuthorizeItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <EuiPanel>
        {view === 'table' && (
          <EuiInMemoryTable
            tableLayout="auto"
            responsive={true}
            items={items}
            compressed={true}
            columns={columns}
            sorting={true}
            pagination={{
              pageSizeOptions: [10, 25, 50, 100, 250],
              initialPageSize: 10,
            }}
          />
        )}
        {lastChangeMsg}
        {view === 'edit' && children}
      </EuiPanel>
      <EuiSpacer size="xl" />
    </>
  );
};
