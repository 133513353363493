import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiTabbedContent,
  EuiTitle,
} from '@elastic/eui';
import { Events, Partners } from '../../_types';
import { useState } from 'react';
import { EventFlyoutPresentationTable } from '../tables/events';
import { PartnerFlyoutTable } from '../tables/partner';
import { useNewsletter } from '../../_functions';
import { EventUserComment, EventUserTable } from '../tables';

export const EventFlyout = ({
  event,
  onClose,
}: {
  event: Events;
  onClose: () => void;
}): JSX.Element => {
  const { partners } = useNewsletter();
  const [selectedTabId, setSelectedTabId] = useState<string>('stand');
  const eventPartnerDetail: Partners[] = partners.filter((partner) =>
    partner.events.some((e) => e.id === event?.event?.id)
  );

  const tabs = [
    {
      id: 'partner--id',
      name: 'Partner',
      content: <PartnerFlyoutTable partners={eventPartnerDetail} />,
    },
    {
      id: 'präsentation--id',
      name: 'Präsentation',
      content: <EventFlyoutPresentationTable presentations={event.presentations} />,
    },
    {
      id: 'guest--id',
      name: 'Gäste',
      content: <EventUserComment customers={event.guests} event={event.event.id} />,
    },
  ];
  return (
    <EuiFlyout onClose={onClose}>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle>
          <h2>{event.event.name}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiSpacer />
        <EuiTabbedContent
          tabs={tabs}
          initialSelectedTab={tabs[0]}
          autoFocus="selected"
          onTabClick={(tab) => {
            setSelectedTabId(tab.id);
          }}
        />
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};
