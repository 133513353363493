import React, { useState } from "react";
import { Campaign, Companie, UserDetail } from "../../../_types";
import { EuiBadge, EuiButton, EuiButtonEmpty, EuiCallOut, EuiComboBox, EuiComboBoxOptionOption, EuiFieldText, EuiFlexGrid, EuiFlexItem, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiTable, EuiTableBody, EuiTableHeader, EuiTableHeaderCell, EuiTableRow, EuiTableRowCell, EuiText } from "@elastic/eui";
import { isValidEmail, useNewsletter } from "../../../_functions";
import { useToast } from "../../../_functions/toast";
export const CreateUserModal = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
    const { companies, campaign, customers, createUser } = useNewsletter()
    const { addToast } = useToast()

    const [email, setEmail] = useState<string | undefined>(undefined)
    const [firstname, setFirstname] = useState<string | undefined>(undefined);
    const [surname, setSurname] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string>("");
    const [companie, setCompanie] = useState<Companie | undefined>(undefined);
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign | undefined>(undefined)
    const [update, setUpdate] = useState<boolean>(false)
    const [oldValues, setOldValues] = useState<UserDetail>()

    const handleChangeCompany = (selectedOptions: EuiComboBoxOptionOption<Companie>[] | string) => {
        if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
            const selectedOption = selectedOptions[0].value;
            setCompanie(selectedOption);
        } else if (!Array.isArray(selectedOptions)) {
            setCompanie({ name: selectedOptions, id: "" })
        }
        else {
            setCompanie(undefined);
        }
    };

    const handleChangeCampaign = (selectedOptions: EuiComboBoxOptionOption<Campaign>[] | string) => {
        if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
            const selectedOption = selectedOptions[0].value;
            setSelectedCampaign(selectedOption);
        } else if (!Array.isArray(selectedOptions)) {
            setSelectedCampaign({ name: selectedOptions, id: "" })
        }
        else {
            setSelectedCampaign(undefined);
        }
    };

    const closeModal = () => {
        setEmail(undefined)
        setFirstname(undefined)
        setSurname(undefined)
        setTitle("")
        setCompanie(undefined)
        setSelectedCampaign(undefined)
        handleClose()
        setUpdate(false)
    }

    const finish = (wantUpdate: boolean) => {
        const allNeededField = email && firstname && surname && companie
        const alreadyExist = customers.some((usr: UserDetail) => usr.email === email);
        if (allNeededField) {
            if (!isValidEmail(email)) {
                addToast({
                    title: 'Fehler',
                    text: <p>Bitte gebe eine gültige E-Mail Adresse ein!</p>,
                    color: "danger",
                    iconType: "cross"
                });
            }
            if (allNeededField && alreadyExist && wantUpdate && isValidEmail(email)) {
                createUser(email, firstname, surname, title, companie.name, selectedCampaign ? selectedCampaign.name : "")
                closeModal()
            }
            if (alreadyExist && !wantUpdate) {
                setOldValues(customers.filter((usr: UserDetail) => usr.email === email)[0])
                setUpdate(true)
            }
            if (allNeededField && !alreadyExist && isValidEmail(email)) {
                createUser(email, firstname, surname, title, companie.name, selectedCampaign ? selectedCampaign.name : "")
                closeModal()
            }
        } else {
            addToast({
                title: 'Fehler beim erstellen',
                text: <EuiText><p>Alle Felder müssen ausgefüllt sein!</p></EuiText>,
                color: "danger",
                iconType: "cross",
            });
        }
    }
    return (
        <EuiModal onClose={closeModal} initialFocus="[name=vorname]">
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Nutzer anlegen</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                {update ?
                    <EuiCallOut title="Nutzer existiert bereits!" color="warning" iconType="help">
                        <p>Es existiert bereits ein Nutzer mit der E-Mail addresse: <strong>{email}</strong>!</p>
                        <p>Wenn du ihn updaten willst klicke auf Updaten.</p>
                        {oldValues &&
                            <>
                                <EuiTable>
                                    <EuiTableHeader>
                                        <EuiTableHeaderCell></EuiTableHeaderCell>
                                        <EuiTableHeaderCell>Neu</EuiTableHeaderCell>
                                        <EuiTableHeaderCell>Alt</EuiTableHeaderCell>
                                    </EuiTableHeader>
                                    <EuiTableBody>
                                        <EuiTableRow>
                                            <EuiTableRowCell>
                                                <EuiText><strong>Name</strong></EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{firstname} {surname}</EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{oldValues.firstname} {oldValues.lastname}</EuiText>
                                            </EuiTableRowCell>
                                        </EuiTableRow>
                                        <EuiTableRow>
                                            <EuiTableRowCell>
                                                <EuiText><strong>E-Mail</strong></EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{email}</EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{oldValues.email}</EuiText>
                                            </EuiTableRowCell>
                                        </EuiTableRow>
                                        <EuiTableRow>
                                            <EuiTableRowCell>
                                                <EuiText><strong>Titel</strong></EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{title}</EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{oldValues.title}</EuiText>
                                            </EuiTableRowCell>
                                        </EuiTableRow>
                                        <EuiTableRow>
                                            <EuiTableRowCell>
                                                <EuiText><strong>Unternehmen</strong></EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{companie?.name}</EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{oldValues.company.name}</EuiText>
                                            </EuiTableRowCell>
                                        </EuiTableRow>
                                        <EuiTableRow>
                                            <EuiTableRowCell>
                                                <EuiText><strong>Kampagne</strong></EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{selectedCampaign?.name}</EuiText>
                                            </EuiTableRowCell>
                                            <EuiTableRowCell>
                                                <EuiText>{oldValues.campaign.name}</EuiText>
                                            </EuiTableRowCell>
                                        </EuiTableRow>
                                    </EuiTableBody>
                                </EuiTable>
                                {oldValues.newsletters && oldValues.newsletters.length > 0 && <EuiFlexGrid>
                                    <EuiFlexItem>
                                        <EuiText>
                                            <strong>Newsletters</strong>
                                        </EuiText>
                                    </EuiFlexItem>
                                    {oldValues.newsletters.forEach((newsletter) => {
                                        return                     <EuiBadge style={{ margin: '5px' }}>
{newsletter.name}</EuiBadge>
                                    })}
                                </EuiFlexGrid>}
                            </>
                        }

                    </EuiCallOut>
                    :
                    <EuiForm component="form">
                        <EuiFormRow>
                            <EuiFieldText
                                placeholder="Vorname"
                                aria-label="Vorname"
                                name="vorname"
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow>
                            <EuiFieldText
                                placeholder="Nachname"
                                aria-label="Nachname"
                                name="nachname"
                                onChange={(e) => setSurname(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow>
                            <EuiFieldText
                                placeholder="E-Mail"
                                aria-label="E-Mail Adresse des Nutzers"
                                name="user"
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                            />
                        </EuiFormRow>
                        <EuiFormRow>
                            <EuiFieldText
                                placeholder="Title"
                                aria-label="Title"
                                name="tile"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow>
                            <EuiComboBox
                                placeholder="Unternehmen"
                                options={companies?.map((company: Companie) => ({
                                    label: company.name,
                                    value: company,
                                }))}
                                selectedOptions={companie ? [{ label: companie.name, value: companie }] : []}
                                onChange={(selectedOptions) => handleChangeCompany(selectedOptions)}
                                onCreateOption={(selectedOptions) => handleChangeCompany(selectedOptions)}
                                isClearable={true}
                                singleSelection
                            />
                        </EuiFormRow>
                        <EuiFormRow>
                            <EuiComboBox
                                placeholder="Kampange"
                                options={campaign?.map((kmpgn: Campaign) => ({
                                    label: kmpgn.name,
                                    value: kmpgn,
                                }))}
                                selectedOptions={selectedCampaign ? [{ label: selectedCampaign.name, value: selectedCampaign }] : []}
                                onChange={(selectedOptions) => handleChangeCampaign(selectedOptions)}
                                onCreateOption={(selectedOptions) => handleChangeCampaign(selectedOptions)}
                                isClearable={true}
                                singleSelection
                            />
                        </EuiFormRow>
                    </EuiForm>
                }

            </EuiModalBody>
            {update ?
                <EuiModalFooter>
                    <EuiFlexItem>
                        <EuiButton fill onClick={() => { closeModal() }} color="warning">
                            Abbrechen
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiButton fill onClick={() => { finish(true) }} color="warning">
                            Updaten
                        </EuiButton>
                    </EuiFlexItem>
                </EuiModalFooter>
                :
                <EuiModalFooter>

                    <EuiButtonEmpty onClick={closeModal}>Abbrechen</EuiButtonEmpty>
                    <EuiButton onClick={() => finish(false)}>Erstellen</EuiButton>
                </EuiModalFooter>
            }
        </EuiModal >
    )
}