import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssignPresentation, QRCodeScanner } from './scann';
import { EuiEmptyPrompt, EuiLoadingChart, EuiPanel } from '@elastic/eui';
import { useApi } from '../_functions';
import { PublicEventData } from '../_types';

export const EventDetection = (): JSX.Element => {
  const { event, partner, presentation } = useParams<{
    event: string;
    partner: string;
    presentation?: string;
  }>();
  const { Get } = useApi();

  const [publicData, setPublicData] = useState<PublicEventData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await Get({
        path: `public/event/${event}/${partner}`,
      });
      setPublicData(response.data);
    } catch (err) {
      console.error(`Error fetching event data for partner ${partner}:`, err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event && partner) {
      fetchData();
    }
  }, [event, partner]);

  if (loading) {
    return <EuiLoadingChart />;
  }

  if (!publicData) {
    return (
      <EuiPanel color="warning" style={{ margin: '20vh auto' }}>
        <EuiEmptyPrompt
          iconType="alert"
          iconColor="warning"
          title={<h2>No Event Data Found</h2>}
          body={<p>We couldn't find any event data for this partner.</p>}
        />
      </EuiPanel>
    );
  }

  if (event && partner && !presentation) {
    return <QRCodeScanner publicData={publicData} />;
  } else if (event && partner && presentation) {
    return (
      <AssignPresentation
        eventId={event}
        partnerId={partner}
        presentetionId={presentation}
        publicData={publicData}
      />
    );
  }

  return (
    <EuiPanel color="warning" style={{ margin: '20vh auto' }}>
      <EuiEmptyPrompt iconType="alert" iconColor="warning" title={<h2>Invalid Partner</h2>} />
    </EuiPanel>
  );
};
