import React, { useState } from 'react';
import { OpsTable } from '../../components/tabel';
import { useNewsletter } from '../../_functions';
import { BasicUser, Companie, Partners } from '../../_types';
import {
  EuiBadge,
  EuiBasicTableColumn,
  EuiFlexGrid,
  EuiFlexItem,
  EuiText,
  EuiToolTip,
} from '@elastic/eui';
import { DeletePartnerModal } from '../modal/user/deletePartner';

export const PartnerTable = (): JSX.Element => {
  const { partners } = useNewsletter();
  const [selectedPartner, setSelectedPartner] = useState<Partners | undefined>(undefined);
  const [modal, setModal] = useState<'delete' | 'none'>('none');

  const handleClose = () => {
    setModal('none');
    setSelectedPartner(undefined);
  };

  const columnsPartners: EuiBasicTableColumn<Partners>[] = [
    {
      field: 'companie',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (companie: Companie) => {
        return (
          <EuiText>
            <p>{companie.name}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'employees',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (employees: BasicUser[]) => {
        if (employees.length === 0) {
          return (
            <EuiText>
              <p>Keine bekannten Nutzer</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {employees.map((e) => (
                <EuiToolTip
                  key={e.id}
                  content={
                    <EuiFlexGrid direction="column">
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Vorname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.firstname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Nachname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.lastname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  }
                >
                                      <EuiBadge style={{ margin: '5px' }}>
{e.email}</EuiBadge>
                </EuiToolTip>
              ))}
            </div>
          );
        }
      },
    },
    {
      field: 'events',
      name: 'Events',
      sortable: true,
      truncateText: true,
      render: (events: Companie[]) => {
        if (events.length === 0) {
          return (
            <EuiText>
              <p>Partner war auf keinem Event</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {events.map((e) => (
                                    <EuiBadge style={{ margin: '5px' }}>

                  <p>{e.name}</p>
                </EuiBadge>
              ))}
            </div>
          );
        }
      },
    },
    {
      field: 'action',
      name: '',
      actions: [
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: Partners) => {
            console.log('Partner', item);
            setSelectedPartner(item);
            setModal('delete');
          },
        },
      ],
      width: '100px',
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={partners}
        compressed={true}
        columns={columnsPartners}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsFilterColumns={['companie', 'employees', 'events']}
        opsStorageKey="partner:full"
        opsSearch
        // opsFilterColumns={}
      />
      {modal === 'delete' && selectedPartner && (
        <DeletePartnerModal handleClose={handleClose} selectedUser={selectedPartner} />
      )}
    </>
  );
};

export const PartnerFlyoutTable = ({ partners }: { partners: Partners[] }): JSX.Element => {
  const [selectedPartner, setSelectedPartner] = useState<Partners | undefined>(undefined);
  const [modal, setModal] = useState<'delete' | 'none'>('none');

  const handleClose = () => {
    setModal('none');
    setSelectedPartner(undefined);
  };

  const columnsPartners: EuiBasicTableColumn<Partners>[] = [
    {
      field: 'companie',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (companie: Companie) => {
        return (
          <EuiText>
            <p>{companie.name}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'employees',
      name: 'Partner',
      sortable: true,
      truncateText: true,
      render: (employees: BasicUser[]) => {
        if (employees.length === 0) {
          return (
            <EuiText>
              <p>Keine bekannten Nutzer</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {employees.map((e) => (
                <EuiToolTip
                  key={e.id}
                  content={
                    <EuiFlexGrid direction="column">
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Vorname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.firstname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGrid direction="row">
                          <EuiFlexItem>
                            <EuiText>
                              <p>Nachname</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              <p>{e.lastname}</p>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGrid>
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  }
                >
                                      <EuiBadge style={{ margin: '5px' }}>
{e.email}</EuiBadge>
                </EuiToolTip>
              ))}
            </div>
          );
        }
      },
    },
    {
      field: 'events',
      name: 'Events',
      sortable: true,
      truncateText: true,
      render: (events: Companie[]) => {
        if (events.length === 0) {
          return (
            <EuiText>
              <p>Partner war auf keinem Event</p>
            </EuiText>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
              }}
            >
              {events.map((e) => (
                                    <EuiBadge style={{ margin: '5px' }}>

                  <p>{e.name}</p>
                </EuiBadge>
              ))}
            </div>
          );
        }
      },
    },
    {
      field: 'action',
      name: '',
      actions: [
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: Partners) => {
            console.log('Partner', item);
            setSelectedPartner(item);
            setModal('delete');
          },
        },
      ],
      width: '100px',
    },
  ];

  return (
    <>
      <OpsTable
        tableLayout="auto"
        responsive={true}
        items={partners}
        compressed={true}
        columns={columnsPartners}
        sorting={true}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250],
          initialPageSize: 10,
        }}
        opsFilterColumns={['companie', 'employees', 'events']}
        opsSearch
        opsStorageKey="partner:event"
        // opsFilterColumns={}
      />
      {modal === 'delete' && selectedPartner && (
        <DeletePartnerModal handleClose={handleClose} selectedUser={selectedPartner} />
      )}
    </>
  );
};
